@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap");
.home {
  background-color: #f8f8f8;
}
.mainBanner {
  /* background-color: #6ac9e4; */
  background-color: #c62641;
  height: 100vh;
  display: flex;
  /* position: fixed; */
}
.mainContent {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 50px 0;
  position: relative;
}
.content1 {
  position: absolute;
  font-size: 600px;
  top: 1%;
  color: rgba(0, 0, 0, 0.1);
  width: 100%;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
}
.mainContent h1 {
  font-size: 80px;
  padding: 200px 0 100px 0;
  color: #fff;
  font-family: "Josefin Sans", sans-serif;
}

/* Typography Section */

.typographySection {
  background-color: #fff;
  margin-top: -100px;
}
.miniHeading {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.miniHeading p {
  color: #333333;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
}
.miniHeading p::after {
  content: "";
  position: absolute;
  background-color: gray;
  width: 50%;
  height: 2px;
  top: 50px;
  left: 25%;
}
.allTypologies {
  padding: 180px 100px 100px 100px;
}

.typologyOne h1 {
  font-size: 45px;
  font-family: "Josefin Sans", sans-serif;
}
.typologyOne {
  position: relative;
  margin-bottom: 150px;
}
.typologyOne .firstletter {
  position: absolute;
  font-size: 250px;
  left: -80px;
  top: -100px;
  color: rgba(0, 0, 0, 0.1);
  font-family: "Josefin Sans", sans-serif;
}

.typologyText {
  padding-top: 20px;
  position: relative;
}
.typologyText::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: lightgray;
  left: 50%;
  top: 110%;
}
.typologyText p {
  padding: 10px;
  font-family: "Domine", serif;
  font-size: 19px;
  word-spacing: 2px;
  color: #444444;
}

/* TypologyText */

/* LastLine */
.lastLine {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
}
.lastLine span {
  font-weight: 600;
  color: #c62641;
}
.button a {
  background-color: #c62641;
  width: 200px;
  padding: 10px 20px;
  color: #fff;
  border-radius: 50px;
  font-weight: 100;
  text-decoration: none !important;
  font-size: 14px;
}
.button a:hover {
  background-color: #fff;
  color: #c62641;
  border: 1px solid #c62641;
  font-weight: 300;
}
