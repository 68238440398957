.mainAbout {
  background-image: url("https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269038/abouttheme_yijubl.jpg");
  background-repeat: no-repeat;
  position: relative;
  z-index: -2;
}
.mainAbout::before {
  background: rgba(9, 23, 33, 0.95) none repeat scroll 0 0;
  background-position: center top;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.mainAboutHeading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  z-index: 1;
  color: white;
}
.mainAboutHeading h1 {
  font-weight: 700;
  word-spacing: 5px;
}

/* Contact */
.contact {
  padding: 50px 100px;
  margin-top: 50px;
}
.contactleft {
  border: 1px solid lightgray;
  padding: 50px;
}
.contactleft h2{
    font-weight: 700;
}
.contact input {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  border: 1px solid lightgray !important;
  outline: none !important;
}
.contact textarea {
  border-radius: 0;
  border: 1px solid lightgray !important;
  outline: none !important;
}
.contactform {
  margin-top: 30px;
}
.contact button {
  width: 100%;
  padding: 10px;
  border: none;
  margin-top: 20px;
  font-weight: 600;
  background-color: #d43b55;
  color: #fff;
  font-size: 14px;
}
/* Contact Left */
.contactright {
  border: 1px solid lightgray;
  border-left: 0px ;
  padding: 0;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e88495;
  padding: 50px;
}
.contactInfo img {
  width: 90px;
  margin-bottom: 10px;
}
.contactInfo p{
    margin: 0;
}
.contactInfo h3{
    margin: 0;
}
.contactAddress{
    display: flex;
    gap: 10px;
   border-bottom: 1px solid Lightgray;
   margin:0px 30px;
   padding: 20px 0px 10px 0;
}
.contactAddress p span{
    font-weight: 800;
    font-size: 16px;
}
.contactAddress p{
    margin: 0;
}
.contactIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:30px;
    color: #e88495;
}
