.footer {
  background-color: lightgray;
  padding: 20px;
}
.footerLogo {
  width: 300px;
}
.footerLeft p {
  padding-left: 50px;
  position: relative;
}
.footerLeft p::after {
  content: "";
  position: absolute;
  width: 85%;
  height: 1px;
  left: 50px;
  bottom: -10px;
  background-color: gray;
}
.footerLeft ul {
  padding-left: 50px;
  display: flex;
  list-style: none;
}
.footerLeft ul li {
  margin-right: 20px;
}
.footerLeft ul li svg {
  font-size: 20px;
}
.footerRight {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding-right: 100px;
}
.footerLinks {
  display: flex;
  list-style: none;
}
.footerLinks ul {
  display: flex;
  list-style: none;
  padding: 0;
  position: relative;
}
.footerLinks ul::after{
    content: "";
    position: absolute;
    width: 95%;
    height: 1px;
    left: 10px;
    bottom: -10px;
    background-color: gray;
}
.footerLinks li {
  margin: 0 14px;
}
.footerLinks ul li a {
  color: #111;
  text-decoration: none;
}
.footerForm{
    margin-top: 20px;
}
.footerForm input {
  margin-left: 50px;
  padding: 7px 20px;
  border: none;
  font-size: 14px;
}
.footerForm button {
  background-color: #e88495;
  border: none;
  padding: 7px 10px;
  font-size: 14px;
  color: #fff;
}
