@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap");
.serviceMain {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px 250px;
}

.headerContainer {
  background-color: #3a9ab0;
  padding: 100px 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
.headerContainer p {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 35px;
  text-align: justify;
  font-family: "Nunito Sans", sans-serif;
}
.serviceCard {
  margin-top: 100px;
}
.serviceCard .serviceCardHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-family: "Nunito Sans", sans-serif;
}
/* .cardOne{
    background-color: #f18b63;
    padding: 0px 50px;
    color: #fff;
    min-height: 300px;
    font-family: 'Nunito Sans', sans-serif;

} */
.serviceCards {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
  text-align: center;
  padding: 50px 10px;
  line-height: 35px;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
}

.serviceCards h2 {
  font-size: 28px;
}
.serviceCards p {
  font-size: 20px;
  margin-top: 10px;
  font-family: "Nunito Sans", sans-serif;
}
.cardOne {
  background-color: #f18b63;
}
.cardTwo {
  background-color: #3a9ab0;
}
.cardThree {
  background-color: #f7bc46;
}

/* serivequot */
.serivequot {
  margin-top: 50px;
  background-color: #f7f7f7;
  padding: 100px 0;
}

.miniservice {
  display: flex;
  flex-direction: column;
  padding: 0px 300px;
  position: relative;
}
.miniservice img {
  width: 100px;
  position: absolute;
  top: -30px;
  left: 20%;
}
.miniservice p {
  z-index: 1;
}

.miniservice .miniserviceText {
  font-size: 30px;
  font-family: "Nunito Sans", sans-serif;
}
.administrator {
  color: #4cb8a1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

/* serviceform */
.serviceform {
  margin: 40px 0;
 
}

.serviceformLeft {
  background-color: #3a9ab0;
  padding: 50px 30px;
}
.serviceformLeft .seriveformheading {
  color: #fff;
  font-size: 30px;
  font-family: "Nunito Sans", sans-serif;
}
.serviceformLeft .seriveformtext {
  color: #fff;
  font-size: 20px;
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1px;
}
.serviceForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}
.serviceForm input {
  padding: 10px;
  border-radius: 1px;
  border: none;
}
.serviceformLeft button{
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border-radius: 50px;
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
}
.serviceformRight{
    padding: 100px 50px;
    font-size: 23px;
    font-family: "Nunito Sans", sans-serif;
}
.paraTwo, .paraThree{
    margin-top: 40px;
}
