
.navv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 100px;

  width: 100%;
  z-index: 99;
  background-color: #c62641;
}
.logo img {
  width: 300px;
}
.menu {
  display: flex;
  gap: 40px;
  font-family: 'Josefin Sans', sans-serif;
  margin: 0;
}
.menu li {
  position: relative;
  list-style: none;
}
/* .menu li::after{
    position: absolute;
    content: "";
    width: 0%;
    background-color: #fff;
    height: 2px;
    bottom: 0px;
    left: 0;
transition: 0.4s ease-in-out;
}
.menu li:hover::after{
    width: 100%;
} */

.menu li a {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 1px;
}
