.mainAbout {
  background-image: url("https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269038/abouttheme_yijubl.jpg");
  background-repeat: no-repeat;
  position: relative;
  z-index: -2;
 
}
.mainAbout::before {
  background: rgba(9, 23, 33, 0.95) none repeat scroll 0 0;
  background-position: center top;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.mainAboutHeading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 130px;
  z-index: 1;
  color: white;
}
.mainAboutHeading h1{
    font-weight: 700;
}
.mainAbout p{
padding: 0px 150px;
text-align: center;
font-size: 20px;
}
.experience-area{
    margin-top: 100px;
}
.aboutTextLeftHeading { 
  font-size: 30px;
}
.aboutTextLeftHeading span{
    font-weight: 600;
}
.aboutTextLeftSubHeading{
    color: #c62641;
    font-weight: 700;
    font-size: 24px;
line-height: 30px;
}
.aboutTextLeftText{
    font-size: 18px;
}

/* Helping */
.Helping{
  margin-top: 100px;
  background-color: #dfeeee;
}
.HelpingDiv{
  padding: 80px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.HelpingDiv::after{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #dfeeee;
  top: 90%;
  left: 50%;
  transform: rotate(45deg);
}
.HelpingDiv p{
  margin-top: 10px;
}

/* Founding */
.founding{
  position: relative;
  margin: 30px 0;
}
.foundingRight{
  padding: 150px 0 70px 20px;
}
.foundingRight p{
  margin-top: 10px;
}
.founding::after{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #fff;
  top: 102%;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
}
.foundingRight h1{
  margin-top: 20px;
}

/* Founding */
.earlyGrowth{
  margin: 30px 0;
  padding: 0px 50px;
  position: relative;
  background-color: #dfeeee;
}
.earlyGrowth::after{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #dfeeee;
  top: 92%;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
}

.earlyGrowthRight p{
  margin-top: 10px;
}
.earlyGrowthRight h1{
  margin-top: 30px;
}

/* Founding */
.series{
  margin: 30px 0;
  padding: 0px 50px;
  position: relative;
  background-color: #fff;
}
.seriesLeft p{
  margin-top: 10px;
}
.seriesLeft h1{
  margin-top: 30px;
}
/* Page-Section */
.page-section{
  margin-top: 50px;
  background-color: #dfeeee;
}
.pageSectionLeft{
  padding: 30px;
}

